.required{
    color: var(--main-color)
}
.input{
    margin: 3px 0px 10px;
    width: 400px;
    background: var(--light-gray);
    border: 1px solid var(--gray);
    border-radius: 5px;
    padding-left: 15px;
    font-size: 13px;
    outline: none;
}
input,select{height: 35px;}
textarea{
    height: 100px;border-radius: 5px;;
}
.btn{
    color:#fff;
    background-color: var(--main-color);
    border: 1px solid var(--main-color);
    border-radius: 5px; padding: 10px 30px;
    cursor: pointer;
}
.btn:hover{
    background-color: transparent; color: var(--main-color);
}