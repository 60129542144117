.table{
    border-collapse: collapse !important;
    width: 100%;
}
.table thead tr{
    background-color: #d9d9d9;
}
.table td, .table th{
    padding: 8px; text-align: center;
}
.table tr:nth-child(odd) td{
    background-color: #f2f2f2;
}
.actions span{
    display: inline-block;
    width: 100px;
    text-decoration: underline;
    cursor: pointer;
    font-size: 13px;
}
.filter{
    width: 100%;
    float:left;
    padding: 10px 0px;
}
.filter > div{
    width: 50%;float:left
}
.downloadFormat{
    float: right;
    position:relative;
    margin-right: 20px;
}
.downloadFormat button{
    background-color: #428bca;
    color: #fff; height: 35px;
    padding: 8px 20px;
    border-radius: 5px;
    border-width: 0px;cursor: pointer;;
}
.downloadFormat > div{
    position: absolute;
    bottom: -51px;
    background-color: #f2f2f2;
    padding: 5px;border-radius: 5px;
    width: 120px;
}
.downloadFormat > div > span{
    display: block; cursor: pointer;;
}
.downloadFormat > div > span:hover{
    color: #428bca;
}
.filterSelect{
    width: 250px;
    height: 35px;
    border-width: 1px;
    border-radius: 5px;
}
.actionLink span{
    display: block; width:100px;
    text-decoration: underline;
    font-size: 13px;
    cursor: pointer;
}