:root{
    --primary-color: #f68b1c;
    --light-mode-color: #fff;
    --dark-mode-color:#141423;
    --light-mode-text-color: #333;
    --dark-mode-text-color: #fdfdfd;
    --very-light-gray: rgba(242,242,242,0.5);
    --light-gray: #f2f2f2;
    --gray: #d9d9d9;
    --light-dark: rgba(0,0,0,0.4);
    --nav-color: #a2a3b7;
    --nav-highlighted: #fff;
    --main-color: #244886;
    --sidebar-width: 200px;
    --nav-height: 50px
}