@import url("./constants.css");
body {
  margin: 0px !important;
  padding: 0px !important;
}
*{box-sizing: border-box; font-size: 15px;}
button, a{
    cursor: pointer;
}
input{outline: none !important}
a{text-decoration: none; color: #333}

[class *= "col-"] {
  float: left
}

.btn {
  padding: 10px 15px;
  border-radius: 8px;
}

.col-1 {
  width: 8.33%
}

.col-2 {
  width: 16.66%
}

.col-3 {
  width: 25%
}

.col-4 {
  width: 33.33%
}

.col-5 {
  width: 41.66%
}

.col-6 {
  width: 50%
}

.col-7 {
  width: 58.33%
}

.col-8 {
  width: 66.66%
}

.col-9 {
  width: 75%
}

.col-10 {
  width: 83.33%
}

.col-11 {
  width: 91.66%
}

.col-12 {
  width: 100%
}

@media only screen and (max-width:767px) {
  .col-xs-1 {
      width: 8.33%
  }

  .col-xs-2 {
      width: 16.66%
  }

  .col-xs-3 {
      width: 25%
  }

  .col-xs-4 {
      width: 33.33%
  }

  .col-xs-5 {
      width: 41.66%
  }

  .col-xs-6 {
      width: 50%
  }

  .col-xs-7 {
      width: 58.33%
  }

  .col-xs-8 {
      width: 66.66%
  }

  .col-xs-9 {
      width: 75%
  }

  .col-xs-10 {
      width: 83.33%
  }

  .col-xs-11 {
      width: 91.66%
  }

  .col-xs-12 {
      width: 100%
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .col-sm-1 {
      width: 8.33%
  }

  .col-sm-2 {
      width: 16.66%
  }

  .col-sm-3 {
      width: 25%
  }

  .col-sm-4 {
      width: 33.33%
  }

  .col-sm-5 {
      width: 41.66%
  }

  .col-sm-6 {
      width: 50%
  }

  .col-sm-7 {
      width: 58.33%
  }

  .col-sm-8 {
      width: 66.66%
  }

  .col-sm-9 {
      width: 75%
  }

  .col-sm-10 {
      width: 83.33%
  }

  .col-sm-11 {
      width: 91.66%
  }

  .col-sm-12 {
      width: 100%
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .col-md-1 {
      width: 8.33%
  }

  .col-md-2 {
      width: 16.66%
  }

  .col-md-3 {
      width: 25%
  }

  .col-md-4 {
      width: 33.33%
  }

  .col-md-5 {
      width: 41.66%
  }

  .col-md-6 {
      width: 50%
  }

  .col-md-7 {
      width: 58.33%
  }

  .col-md-8 {
      width: 66.66%
  }

  .col-md-9 {
      width: 75%
  }

  .col-md-10 {
      width: 83.33%
  }

  .col-md-11 {
      width: 91.66%
  }

  .col-md-12 {
      width: 100%
  }
}

@media only screen and (min-width: 1201px) {
  .col-lg-1 {
      width: 8.33%
  }

  .col-lg-2 {
      width: 16.66%
  }

  .col-lg-3 {
      width: 25%
  }

  .col-lg-4 {
      width: 33.33%
  }

  .col-lg-5 {
      width: 41.66%
  }

  .col-lg-6 {
      width: 50%
  }

  .col-lg-7 {
      width: 58.33%
  }

  .col-lg-8 {
      width: 66.66%
  }

  .col-lg-9 {
      width: 75%
  }

  .col-lg-10 {
      width: 83.33%
  }

  .col-lg-11 {
      width: 91.66%
  }

  .col-lg-12 {
      width: 100%
  }
}
.btn{background-color: var(--primary-color);color: white;border-width: 0px;padding: 10px 30px; border-radius: 20px;outline:none !important}
.btn:hover{border: 2px solid var(--primary-color); background-color: transparent;color: var(--primary-color);}
.cancelBtn{
    border-radius: 20px;
    padding:10px 15px;
    background-color: transparent;
    border: 1px solid red;
}
.appDiv{
    box-shadow: 0px 1px 17px 0px rgb(224 224 224);
    border: 1px solid #d9d9d9;
    padding: 20px; border-radius: 5px;
    margin: 5px;
}
.appDiv h3{margin: 0px; margin-bottom: 5px; color: #244886}
.appDiv a{text-decoration: underline;}
.cancelBtn{
    margin-right: 5px;
}