.home{
    width: 100%;
    height: 100vh;
    float: left;
}
.home > div{float: left}
.sidebar{
    height: 100%;
    width: var(--sidebar-width);
    border-right: 1px solid #d9d9d9;
    padding-top: 20px;
    float: left;
    position: fixed;
    background-color: #fff;
    z-index: 1000;
}
.sidebarheader{
    text-align: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #d9d9d9;
}
.content{
    width: calc(100% - var(--sidebar-width));
    height: 100%;
    float: left;
    margin-left: var(--sidebar-width);
}
.content > div{float: left;}
.navbar{
    height: var(--nav-height);
    width: calc(100% - var(--sidebar-width));
    border-bottom: 1px solid #d9d9d9;
    padding: 10px; 
    position:fixed;
    background-color: #fff;
    z-index: 100;
}
.rightnav{
    float: right;
    width: 180px;
}
.rightnav > span{
    float: left
}
.rightnav > span:nth-child(2){
    padding-top: 4px;
}
.rightnav > span:last-child{
    padding-top: 7px;
    margin-left: 20px;
    color: #aaa;
}
.iconPersonCircle{
    color: #777;
    font-size: 25px;
    margin-right: 10px;
}
.brand{display: block;}
.menu{
    float:left;width:100%;
}
.menu a{
    float:left;width: 100%;height: 30px;
    padding: 10px 10px; cursor: pointer;
    border-width: 0px 0px 0px 6px;
    border-style: solid; height: 45px;
    border-color: transparent;
    text-decoration: none;
    color: #333;
} 
.mainContent{
    width: 100%;
    height: calc(100% - var(--nav-height));
    padding: 20px;
    padding-top: calc(20px + var(--nav-height));
    overflow-x: scroll;
}  
.linkstyle{
    border-color: #428bca !important;
    background-color: #dfdfdf;
}

@media screen and (min-width: 768px) {
    
}

@media screen and (min-width: 410px) {
    
}

@media screen and (max-width: 410px) {
    
}