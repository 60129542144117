.loginDiv{
    height: 100vh
}
.loginDiv > div{
    height: 100%;
}
.loginDiv > div:first-child{
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("../images/data-center-shot.webp");
    background-size: 100% 100%;
}
.loginDiv > div:last-child > div{
    box-shadow: 0px 1px 17px 0px rgb(224 224 224);padding: 20px 30px 20px;
    border-radius: 10px;
}
@media screen and (min-width: 769px) {
    .loginDiv > div{
        display: flex; justify-content: center;align-items: center;
    }
}
@media screen and (max-width: 768px) {
    .loginDiv > div:first-child{
        display: none;
    }
    .loginDiv > div:last-child{
        padding: 30px 5%;
    }
}